<template>
  <div style="padding-right: 3.5em">
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showsnackbar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex px-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card class="mb-1">
                <v-card-title
                  style="
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                  "
                >
                  Open Orders
                  <v-spacer></v-spacer>
                  <v-col md="2">
                    <v-form
                      ref="filterform"
                      lazy-validation
                      @submit.prevent="getSearch"
                    >
                      <v-text-field
                        v-model="searchKey"
                        append-icon="mdi-magnify"
                        outlined
                        placeholder="Search"
                        dense
                        color="black"
                        single-line
                        hide-details
                        @click:append="getSearch"
                      ></v-text-field>
                      <v-btn
                        type="submit"
                        class="hidden-sm-and-down hidden-md-and-up"
                        >Submit</v-btn
                      >
                    </v-form>
                  </v-col>

                  <v-dialog v-model="filter" max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        outlined
                        color="primary"
                        @click="fliter = true"
                        dense
                      >
                        Filtered Search
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="headline grey lighten-2">
                        Filtered Search
                      </v-card-title>

                      <v-card-text>
                        <form
                          ref="bthfilter"
                          lazy-validation
                          @submit.prevent="getSearch"
                          class="pt-4"
                        >
                          <v-text-field
                            outlined
                            dense
                            v-model="company"
                            label="Company"
                          ></v-text-field>

                          <!-- <v-text-field
                            outlined
                            dense
                            v-model="description"
                            label="Description"
                          ></v-text-field> -->

                          <v-text-field
                            outlined
                            dense
                            v-model="phone"
                            label="Phone"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            dense
                            v-model="orderID"
                            label="Order ID"
                          ></v-text-field>
                          <v-btn
                            type="submit"
                            class="hidden-sm-and-down hidden-md-and-up"
                            >Submit</v-btn
                          >
                        </form>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="error" @click="closeFilter">
                          Clear
                        </v-btn>
                        <v-btn color="primary" class="mr-4" @click="getSearch">
                          Search
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-card-title>
                <v-progress-linear
                  :active="isLoading"
                  indeterminate
                  absolute
                  bottom
                  color="primary"
                ></v-progress-linear>
              </v-card>
              <v-card class="mb-4">
                <v-card-text
                  style="
                    padding: 0px !important;
                    padding-top: 10px !important;
                    height: 4em !important;
                  "
                >
                  <v-layout wrap style="align-items: center" justify-center>
                    <v-flex xs6 sm6 md6></v-flex>
                    <v-flex xs6 sm6 md6>
                      <v-layout wrap justify-center>
                        <v-flex xs6 sm6 md4 text-right pt-2
                          >Items Per Page</v-flex
                        >
                        <v-flex xs6 sm6 md2>
                          <v-select
                            solo
                            flat
                            v-model="itemsPerPage"
                            :items="ItemsCount"
                            dense
                            required
                            
                          ></v-select>
                        </v-flex>
                        <v-flex
                          xs6
                          sm6
                          md2
                          text-center
                          pt-2
                          style="color: black"
                        >
                          {{ pageStart }}-{{ pageEnd }} of {{ count }}
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-right>
                          <span v-if="page > 1">
                            <v-icon @click="prevpage" color="primary"
                              >mdi-chevron-left</v-icon
                            >
                          </span>
                          <span v-else>
                            <v-icon disabled color="primary"
                              >mdi-chevron-left</v-icon
                            >
                          </span>
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-center>
                          <!-- <v-text-field
                              v-model="page"
                              color="#26af82"
                              min="1"
                              :dense="true"
                              :max="totalpages"
                              type="number"
                              class="centered-input"
                            ></v-text-field> -->
                          {{ page }}
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-left>
                          <span v-if="page < totalpages">
                            <v-icon @click="nextpage" color="primary"
                              >mdi-chevron-right</v-icon
                            >
                          </span>
                          <span v-else>
                            <v-icon disabled color="primary"
                              >mdi-chevron-right</v-icon
                            >
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="customers"
                  :items-per-page="itemsPerPage"
                  hide-default-footer
                  dense
                  class="row-pointer pb-12 tableValue"
                >
                  <template v-slot:item.orderID="{ item }">
                    <v-layout wrap justify-center @click="rowClicked(item)">
                      <v-flex xs12 text-align-center>
                        <!-- <router-link tag='span' :to="'/Order/'+item._id">
                            <v-btn small text color="blue" class="text-capitalize">{{ item.orderID }}</v-btn>
                    </router-link> -->
                        <span v-if="item.orderID" style="color: darkblue">{{
                          item.orderID
                        }}</span>
                      </v-flex>
                    </v-layout>
                  </template>
                  <template v-slot:item.orderDate="{ item }">
                    <span v-if="item.orderDate">{{
                      ChangeFormateDate(item.orderDate)
                    }}</span>
                  </template>
                  <template v-slot:item.shippingDate="{ item }">
                    <span v-if="item.shippingDate">{{
                      ChangeFormateDate(item.shippingDate)
                    }}</span>
                  </template>
                  <template v-slot:item.billToCompany="{ item }">
                    <span
                      v-if="item.billToCompany"
                      v-html="item.billToCompany.slice(0, 15)"
                    >
                      <!-- {{ item.productDescription.slice(0, 40) }} -->..
                    </span>
                  </template>
                  <template v-slot:item.productUnitPrice="{ item }">
                    <span v-if="item.productUnitPrice"
                      >${{ item.productUnitPrice }}</span
                    >
                    <span v-else>$0</span>
                  </template>
                  <template v-slot:item.agentCode="{ item }">
                    <span v-if="item.customerId"
                      >{{ item.customerId.agentCode
                      }}<span v-if="item.updated_by"
                        >/{{ item.updated_by.agentCode }}</span
                      ></span
                    >
                  </template>
                  <template v-slot:item.productDescription="{ item }">
                    <span
                      v-if="item.productDescription"
                      v-html="item.productDescription.slice(0, 30)"
                    >
                      <!-- {{ item.productDescription.slice(0, 40) }} -->..
                    </span>
                  </template>
                  <template v-slot:item.totalAmount="{ item }">
                    <span v-if="item.totalAmount">${{ item.totalAmount }}</span>
                    <span v-else>$0</span>
                  </template>
                  <template v-slot:item._id="{ item }">
                    <v-menu
                      v-model="item.menu"
                      :close-on-content-click="false"
                      max-width="200px"
                      offset-y
                      open-on-hover
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          v-if="item.attachments.length > 0"
                          color="blue"
                          v-bind="attrs"
                          v-on="on"
                          @click="popup(item)"
                          class="mr-2"
                        >
                          mdi-eye
                        </v-icon>

                        <v-icon small v-else color="grey" class="mr-2">
                          mdi-eye-off
                        </v-icon>
                      </template>

                      <!-- <v-card>
                        <v-list>
                          <v-list-item>
                            <v-img
                              v-if="item.attachments[0]"
                              :src="
                                baseURL + '/file/get/' + item.attachments[0]
                              "
                              height="200px"
                              width="auto"
                              contain
                            ></v-img>
                          </v-list-item>
                        </v-list>
                      </v-card> -->
                    </v-menu>
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                          @click="delConfirmation(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>

                      <v-card>
                        <v-card-title class="headline grey lighten-2">
                          Delete Confirmation
                        </v-card-title>

                        <v-card-text>
                          <span style="font-size: 16px; font-weight: 600"
                            >Please confirm that you want to Remove this Order
                            details ?
                          </span>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn color="primary" @click="item.delete = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            color="error"
                            class="mr-4"
                            @click="deleteOrder(item)"
                          >
                            Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <!-- <template v-slot:top="{ pagination, options, updateOptions }">
                    <v-data-footer
                      :pagination="pagination"
                      :options="options"
                      @update:options="updateOptions"
                      :items-per-page-options="itemsperpage"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                  </template> -->
                </v-data-table>
                <!-- <v-card-text>
                  <v-divider></v-divider>
                  <v-layout wrap class="pt-4">
                    <v-flex xs9 md9 text-right>
                      <span class="black--text"><b>Total Open Orders</b></span>
                    </v-flex>
                    <v-flex
                      class="font-weight-medium black--text"
                      xs3
                      md1
                      text-right
                    >
                      $
                    </v-flex>
                  </v-layout>
                </v-card-text> -->
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>

    <v-navigation-drawer
      style="position: fixed; padding-top: 5%"
      right
      width="60"
    >
      <v-list nav dense>
        <!-- <v-list-item link to="" title="Open Orders">
          <v-list-item-icon>
            <v-badge color="red" offset-y="4" content="100" overlap>
              <v-icon color="info" router-link to="/openOrders">mdi-timer-sand</v-icon>
            </v-badge>
          </v-list-item-icon>
          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item> -->
        <v-list-item link to="" title="Excel">
          <v-list-item-icon>
            <vue-excel-xlsx
              :data="customers"
              :columns="columns"
              :filename="'Order List'"
              ><v-icon color="primary">mdi-file-excel</v-icon></vue-excel-xlsx
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>
        <v-list-item link title="Pdf">
          <v-list-item-icon>
            <a
              v-if="searchKey || company || phone || orderID"
              :href="
                'https://www.abblabels.ml/pdf/generate/Orders_Report/?limit=' +
                itemsPerPage +
                '&page=' +
                page +
                '&pdfType=search' +
                '&keyword=' +
                searchKey +
                '&company=' +
                company +
                '&phone=' +
                phone +
                '&orderID=' +
                orderID +
                '&orderType=' +
                'open'
              "
              class="downlink"
            >
              <v-icon color="primary">mdi-file-pdf</v-icon></a
            >
            <a
              v-else
              :href="
                'https://www.abblabels.ml/pdf/generate/Orders_Report/?limit=' +
                itemsPerPage +
                '&page=' +
                page +
                '&orderType=' +
                'open'
              "
              class="downlink"
            >
              <v-icon color="primary">mdi-file-pdf</v-icon></a
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item link title="Batch Print">
          <v-list-item-icon>
            <v-icon color="primary" @click="batchPrint = true"
              >mdi-printer</v-icon
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
          <v-dialog v-model="batchPrint" max-width="300">
            

            <v-card>
              <v-card-title class="headline grey lighten-2">
                Batch Print
              </v-card-title>

              <v-card-text>
                <form ref="bthPrint" class="pt-4">
                  <v-text-field
                    v-model="start"
                    label="Start with #."
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="until"
                    label="Until #."
                    dense
                    outlined
                    required
                  ></v-text-field>
                </form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="error" @click="batchPrint = false">
                  Cancel
                </v-btn>
                <v-btn color="primary" class="mr-4" @click="batchPrintdata">
                  Print
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item> -->
        <!-- <v-list-item link to="/addOrder" title="Add">
          <v-list-item-icon>
            <v-icon color="primary">mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      appConfirmation: false,
      deleteId: null,
      delete: false,
      menu: false,
      headers: [
        { text: "Ord#", align: "start", value: "orderID", width: "8%" },
        { text: "Date", value: "orderDate", width: "8%" },
        { text: "Ship Date", value: "shippingDate", width: "8%" },
        { text: "Company", value: "billToCompany", width: "12%" },
        { text: "Qty", value: "productQuantity", align: "end", width: "6%" },
        { text: "Description", value: "productDescription", width: "20%" },
        { text: "Phone", value: "phone", width: "12%", align: "center",  },
        { text: "Price", value: "productUnitPrice", width: "6%", align: "end" },
        { text: "Total", value: "totalAmount", width: "6%", align: "end" },
        { text: "Agent", value: "agentCode", width: "7%" },
        { text: "Actions", value: "_id", sortable: false, width: "10%" },
      ],
      columns: [
        { label: "Ord#", field: "orderID" },
        {
          label: "Date",
          field: "orderDate",
          dataFormat: this.ChangeFormateDate,
        },
        {
          label: "Ship Date",
          field: "shippingDate",
          dataFormat: this.ChangeFormateDate,
        },
        { label: "Company", field: "billToCompany" },
        { label: "Qty", field: "productQuantity" },
        { label: "Description", field: "productDescription" },
        { label: "Phone", field: "phone" },
        { label: "Price", field: "productUnitPrice" },
        { label: "Total", field: "totalAmount" },
        // { text: "Agent/User", value: "agentCode" },
      ],
      searchKey: null,
      company: null,
      description: null,
      phone: null,
      orderID: null,
      mini: true,
      customers: [],
      pageStart: 0,
      pageEnd: 0,
      count: 0,
      itemsPerPage: 20,
      page: 1,
      totalpages: 0,
      ItemsCount: [20, 30, 50, 100],
      showsnackbar: false,
      msg: "",
      timeout: 2000,
      batchPrint: false,
      start: null,
      until: null,
      filter: false,
      isLoading: false,
    };
  },
  beforeMount() {
    this.getOrders();
  },
  watch: {
    // searchKey: function () {
    //   this.getSearch();
    // },
    // company: function () {
    //   this.getSearch();
    // },
    // description: function () {
    //   this.getSearch();
    // },
    // phone: function () {
    //   this.getSearch();
    // },

    // orderID: function () {
    //   this.getSearch();
    // },
    itemsPerPage: function () {
      // this.page = 1;
      if (this.searchKey || this.company || this.phone || this.orderID) {
        this.getSearch();
      } else this.getOrders();
    },
  },
  methods: {
    prevpage() {
      if (this.page > 1) {
        if (this.searchKey || this.company || this.phone || this.orderID) {
          this.page--;
          this.getSearch();
        } else {
          this.page--;
          this.getOrders();
        }
      }
    },
    nextpage() {
      if (this.page < this.totalpages) {
        if (this.searchKey || this.company || this.phone || this.orderID) {
          this.page++;
          this.getSearch();
        } else {
          this.page++;
          this.getOrders();
        }
      }
    },
    // batchPrintdata() {
    //   console.log("here");
    // },
    getOrders() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/openOrders/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // status:"active",
          limit: this.itemsPerPage,
          page: this.page,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.customers = response.data.data;
          this.count = response.data.totalLength;
          this.totalpages = Math.ceil(
            response.data.totalLength / this.itemsPerPage
          );

          if (response.data.length > 0) {
            this.pageStart = (response.data.page - 1) * response.data.limit + 1;
            this.pageEnd = this.pageStart + response.data.length - 1;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },

    closeFilter() {
      this.filter = false;
      this.company = "";
      this.phone = "";
      this.orderID = "";
      this.getOrders();
    },

    getSearch() {
      this.appLoading = false;
      this.filter = false;
      var data = {};
      data.limit = this.itemsPerPage;
      data.page = this.page;
      if (this.searchKey != "") data.keyword = this.searchKey;
      else this.getOrders();
      data.company = this.company;
      data.description = this.description;
      data.phone = this.phone;
      data.orderID = this.orderID;
      data.orderType = "open";

      axios({
        method: "POST",
        url: "/order/search",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            // if (this.searchKey == "" || this.searchKey == null) {
            // this.getOrders();
            // } else {
            this.customers = response.data.data;
            this.count = response.data.totalLength;
            this.totalpages = Math.ceil(
              response.data.totalLength / this.itemsPerPage
            );

            if (response.data.length > 0) {
              this.pageStart =
                (response.data.page - 1) * response.data.limit + 1;
              this.pageEnd = this.pageStart + response.data.length - 1;
            }
            this.company = "";
            this.phone = "";
            this.orderID = "";
            // }
          }
        })

        .catch((err) => {
          console.log(err);
        });
    },
    popup(item) {
      window.open(
        "https://www.abblabels.ml/file/get/" + item.attachments[0],
        "_blank"
      );
    },

    delConfirmation() {
      this.delete = true;
    },
    deleteOrder(item) {
      this.appConfirmation = false;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/order/delete/" + item._id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.getOrders();
            this.delete = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          } else {
            this.delete = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showsnackbar = true;
        });
    },

    ChangeFormateDate(oldDate) {
      if (oldDate) {
        var newdateFormat = oldDate.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }

        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];

        return month + "/" + day + "/" + year;
      }
    },

    rowClicked(item) {
      this.$router.push("/Order/" + item._id);
    },
  },
};
</script>
<style >
tbody tr:nth-of-type(odd) {
  background-color: rgba(28, 133, 219, 0.05);
}
.class-on-data-table table {
  table-layout: fixed;
}
.row-pointer > .v-data-table__wrapper > table > tbody > tr :hover {
  cursor: pointer;
}
a {
  text-decoration: none !important;
}
.v-data-table td,
.v-data-table th {
  padding: 0 10px !important;
}
.v-data-table td,
.v-data-table th {
  padding: 0 10px !important;
}
.v-data-table td {
  font-size: 0.7em;
}
</style>



